<template>
  <div class="position container">
    <div class="title">操作日志</div>
    <section class="clearfix operate">
      <el-input
        size="small"
        placeholder="支持模糊查询"
        prefix-icon="el-icon-search"
        v-model="logValue"
        @change="searchValue"
      ></el-input>
      <el-date-picker
        class="fr date-picker"
        v-model="dateValue"
        value-format="yyyy-MM-dd HH:mm:ss"
        type="daterange"
        range-separator="至"
        size="small"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        @change="getLog"
        :clearable="false"
        :picker-options="pickerOptions"
      >
      </el-date-picker>
    </section>
    <div class="table">
      <el-table :data="logData">
        <el-table-column  label="操作时间" width="200">
          <template slot-scope="scope">
            {{ scope.row.createdAt | myTimeToLocal}}
          </template>
        </el-table-column>
        <el-table-column  prop="name" label="操作厂商"> </el-table-column>
        <el-table-column  prop="detail" label="详细描述"></el-table-column>
        <el-table-column  prop="ip" label="操作IP记录" width="150"></el-table-column>
      </el-table>
      <el-pagination
        class="fr pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.currentPage"
        :page-sizes="pagination.pageSizes"
        :page-size="pagination.pageSize"
        :total="pagination.total"
        layout="total, sizes, prev, pager, next, jumper"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import users from "/src/api/user.js";
import moment from "moment";

export default {
  data() {
    return {
      logData: [],
      dateValue: [
        moment().subtract(1, "months").format("YYYY-MM-DD HH:mm:ss"),
        moment().format("YYYY-MM-DD HH:mm:ss"),
      ],
      logValue: "",
      moment,
      params: {
        page: 1,
        size: 20,
        keyword: "",
        startTime: "",
        endTime: "",
      },
      pagination: {
        total: 0,
        currentPage: 1,
        pageSize: 20,
        pageSizes: [10, 15, 20],
      },
        pickerOptions: {
            disabledDate(time) {
                return time.getTime() > Date.now();
            }
        }
    };
  },
  mounted() {
    this.params.startTime = moment().subtract(1, "months").startOf("day").toISOString("YYYY-MM-DDTHH:mm:ss[Z]");
    this.params.endTime = moment().toISOString("YYYY-MM-DDTHH:mm:ss[Z]");
    this.getLog();
  },
  watch: {
    dateValue: {
      handler(newVal) {
        if (newVal.length === 0) return;
        this.params.startTime = moment(newVal[0]).format("YYYY-MM-DDTHH:mm:ss[Z]");
        this.params.endTime = moment(newVal[1]).format("YYYY-MM-DDTHH:mm:ss[Z]");
      },
    },
  },

  filters: {
    myTimeToLocal: function (inputTime) {
        if (!inputTime && typeof inputTime !== "number") {
            return "";
        }
        var localTime = "";
        inputTime = new Date(inputTime).getTime();
        const offset = new Date().getTimezoneOffset();
        localTime = new Date(inputTime - offset * 60000).toISOString();
        localTime = localTime.substr(0, localTime.lastIndexOf("."));
        localTime = localTime.replace("T", " ").slice(0,16);
        return localTime;
    }
},

  methods: {
    getLog() {
      users.getLog(this.params).then((item) => {
        this.logData = item.logs;
        this.pagination.total = item.count;
      });
    },
    handleSizeChange(val) {
      this.params.size = val;
      this.getLog(this.params);
    },

    handleCurrentChange(val) {
      this.params.page = val;
      this.getLog(this.params);
    },

    searchValue() {
      if (this.logValue.length) {
        this.params.keyword = this.logValue;
      } else {
        this.params.keyword = "";
      }
      this.getLog(this.params);
    },
  },
};
</script>
<style lang="scss" scoped>
.position {
  overflow: scroll;
  .operate {
    margin: 10px 10px 40px 10px
  }
  .el-input {
    width: 20%;
  }
  .pagination {
    margin: 40px 70px 0 0;
  }
}
</style>
